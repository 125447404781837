@font-face {
  src: url("fonts/TradeGothicLT/font.eot");
  font-family: "TradeGothicLT";
  font-display: swap;
  src: url("fonts/TradeGothicLT/font.woff2") format("woff2"), url("fonts/TradeGothicLT/font.woff") format("woff"),
    url("fonts/TradeGothicLT/font.eot?#iefix") format("embedded-opentype"),
    url("fonts/TradeGothicLT/font.ttf") format("truetype");
}
@font-face {
  src: url("fonts/TradeGothicLT-BoldTwo/font.eot");
  font-family: "TradeGothicLT-BoldTwo";
  font-display: swap;
  src: url("fonts/TradeGothicLT-BoldTwo/font.woff2") format("woff2"),
    url("fonts/TradeGothicLT-BoldTwo/font.woff") format("woff"),
    url("fonts/TradeGothicLT-BoldTwo/font.eot?#iefix") format("embedded-opentype"),
    url("fonts/TradeGothicLT-BoldTwo/font.ttf") format("truetype");
}
@font-face {
  src: url("fonts/TradeGothicLT-BoldCondTwenty/font.eot");
  font-family: "TradeGothicLT-BoldCondTwenty";
  font-display: swap;
  src: url("fonts/TradeGothicLT-BoldCondTwenty/font.woff2") format("woff2"),
    url("fonts/TradeGothicLT-BoldCondTwenty/font.woff") format("woff"),
    url("fonts/TradeGothicLT-BoldCondTwenty/font.eot?#iefix") format("embedded-opentype"),
    url("fonts/TradeGothicLT-BoldCondTwenty/font.ttf") format("truetype");
}
